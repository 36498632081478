import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

const Slide = ({ data, transition }) => {
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    setTimeout(() => setOpacity(1), 2000)
    return () => setOpacity(0)
  }, [])

  return (
    <div
      style={{
        width: '100%',
        transition: 'all 1s ease',
        opacity,
      }}
    >
      <div
        style={transition && transition.style}
        dangerouslySetInnerHTML={{ __html: data.slide.html }}
      />
    </div>
  )
}

export const query = graphql`
  query SlideQuery($index: Int!) {
    slide(index: { eq: $index }) {
      html
      index
    }
  }
`
export default Slide
